@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Workday Adelle Sans';
    src: url('https://static.workday.com/fonts/Workday_Adelle_Sans/Workday_Adelle_Sans-Regular.woff2');
}

body {
    margin: 0;
    font-family: 'Workday Adelle Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F0F1F2;
}

@layer components {
    .dub-logo>img {
        height: 100%;
    }
}